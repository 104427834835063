import React from "react";


export default class Main extends React.Component{
    render(){
        return ( 
            <main className="main">
                <h1 className="main--name">Armaan Gomes.</h1>
                <p>Hello. I am Armaan Gomes, although I expect you already know that. Anyway, more about me: I'm Armaan, a high school student interested in CS, Physics, and Robotics. If you stumble across me in my natural habitat, you will probably catch me working on a random problem that has begun bugging me, from wildfire containment to the most efficient way to get out of bed and ready for school (minimizing # of steps of course). Check out my medium at <a class = "textLink" href="https://medium.com/@acgo8888">https://medium.com/@acgo8888</a> and stay on the look out for future articles. That should be all for now, I will retreat to my most recent problem solving session while you continue exploring the site.</p>
                

            </main>
        )
    }
}