import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar from "./components/Navbar.js";
import Main from "./components/Main.js";

class App extends React.Component{

    render(){
        return <body><Navbar/><Main/></body>
    }
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App/>);
