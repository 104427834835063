import React from "react";
import logo from "../logo.png"
import resume from "../Resume.pdf"

export default class Navbar extends React.Component{
    render(){
        return (
        <nav className="nav">
            <img  src={logo} alt="Cool Logo" width="75px"/>
            <a href={resume} className="button">Resume</a>
      



        </nav>)


    }
}